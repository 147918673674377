import { ITracker } from "@/server/models/tracker";
import Axios, { isAxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { FormSchemaType as TrackerFormSchemaType } from "../_components/Tracker/Form";

export const handleAxiosError =
  (callback?: (err: Error) => void) => (err: Error) => {
    if (isAxiosError(err) && err.response?.status === 401) {
      if (location.pathname !== `/api/auth/signin`) {
        location.href = `/api/auth/signin`;
      }
    }
    if (callback) {
      callback(err);
    }
  };

const DEFAULT_MUTATION_ERROR = (err: Error) => {
  console.error(err);

  if (isAxiosError(err)) {
    if (err.response?.data?.error?.message) {
      // toast(err.response.data.error.message, { type: 'error' });
    } else {
      // toast('An unexpected error has occurred', { type: 'error' });
    }
  }
};

export const useAxios = () => {
  const axios = Axios.create({
    baseURL: `/api`,
  });

  axios.interceptors.request.use(config => {
    config.headers = config.headers || {};

    const jwt = localStorage.getItem('account_auth_token');
    if (jwt) {
      config.headers['x-auth-token'] = jwt;
    }

    return config;
  });

  return axios;
};

export const useTrackerList = (params: { id?: string }) => {
  const axios = useAxios();

  const query = useQuery(
    ['/tracker', params],
    () => axios.get<{ data: ITracker[] }>(`/tracker`, {
      params,
    }),
    {
      retry: false,
      onError: handleAxiosError(),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    trackers: query.data?.data.data || [],
    query: query,
  };
}

export const useTrackerUpdateMutation = ({
  onSuccess,
}: {
  onSuccess(): void;
}) => {
  const axios = useAxios();

  return useMutation(
    ({ id, ...body }: TrackerFormSchemaType & { id?: string }) => id ? axios.put(`/tracker/${id}`, body) : axios.post(`/tracker`, body),
    {
      onSuccess,
      onError: handleAxiosError(DEFAULT_MUTATION_ERROR),
    }
  );
};

export const useTrackerDeleteMutation = ({
  onSuccess,
}: {
  onSuccess(): void;
}) => {
  const axios = useAxios();

  return useMutation(
    ({ id }: { id: string }) => axios.delete(`/tracker/${id}`),
    {
      onSuccess,
      onError: handleAxiosError(DEFAULT_MUTATION_ERROR),
    }
  );
};
