"use client"

import { Form, FormBody, FormField, FormFooter } from '../Form';
import { Input } from '../Input';
import { UseFormReturn } from 'react-hook-form';
import { Checkbox } from '../Checkbox';
import { z } from 'zod';
import { Button } from '../Button';

export const formSchema = z.object({
  enabled: z.boolean(),
  name: z.string().trim().min(1, 'Required'),
  url: z.string().url().trim().min(1, 'Required'),
  delay: z.coerce.number().int().min(0),
  waitForSelector: z.string().trim(),
  limitToSelector: z.string().trim(),
  scrollToBottom: z.boolean(),
});

export type FormSchemaType = z.infer<typeof formSchema>;

export const DEFAULT_TRACKER_INFO = {
  enabled: true,
  name: '',
  url: '',
  delay: 0,
  waitForSelector: '',
  limitToSelector: '',
  scrollToBottom: false,
};

export interface ITrackerFormProps {
  form: UseFormReturn<FormSchemaType>;
  onSubmit(): void;
  loading: boolean;
}

export function TrackerForm(props: ITrackerFormProps) {
  const { form, onSubmit, loading } = props;

  return (
    <Form form={form} onSubmit={onSubmit}>
      <FormBody>
        <FormField
          control={form.control}
          name="enabled"
          label=""
          disabled={loading}
          render={({ field }) => (
            <Checkbox
              {...field}
              onChangeChecked={field.onChange}
              label={`Enabled`}
              disabled={loading}
            />
          )}
        />
        <FormField
          control={form.control}
          name="name"
          label="Name"
          description="Give your tracker an internal name so you can easily identify it later"
          required
          disabled={loading}
          render={({ field }) => <Input autoFocus {...field} flex type="text" />}
        />
        <FormField
          control={form.control}
          name="url"
          label="URL"
          description="The URL to track"
          required
          disabled={loading}
          render={({ field }) => <Input {...field} flex type="text" />}
        />
        <FormField
          control={form.control}
          name="delay"
          label="Delay"
          description="Wait this many seconds for page to load"
          disabled={loading}
          render={({ field }) => <Input {...field} flex type="number" />}
        />
        <FormField
          control={form.control}
          name="waitForSelector"
          label="Wait for Selector"
          description="Wait for this CSS selector to be present"
          disabled={loading}
          render={({ field }) => <Input {...field} flex type="text" />}
        />
        <FormField
          control={form.control}
          name="limitToSelector"
          label="Limit to Selector"
          description="Only process content inside this CSS selector"
          disabled={loading}
          render={({ field }) => <Input {...field} flex type="text" />}
        />
        <FormField
          control={form.control}
          name="scrollToBottom"
          label=""
          disabled={loading}
          render={({ field }) => (
            <Checkbox
              {...field}
              onChangeChecked={field.onChange}
              label={`Scroll to bottom of page`}
              disabled={loading}
            />
          )}
        />
      </FormBody>
      <FormFooter>
        <Button type="submit" disabled={loading}>Submit</Button>
      </FormFooter>
    </Form>
  );
}
