"use client"

import { useTrackerUpdateMutation, useTrackerList } from '../../lib/tracker';
import { Modal } from '../Modal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DEFAULT_TRACKER_INFO, formSchema, FormSchemaType, TrackerForm } from './Form';
import { useEffect } from 'react';

export interface IModalProps {
  trackerId: string;
  onClose(): void;
  onUpdate(): void;
}

export function EditTrackerModal({ trackerId, onClose, onUpdate }: IModalProps) {
  const { trackers: [tracker = null], query: { refetch } } = useTrackerList({ id: trackerId });

  const mutation = useTrackerUpdateMutation({
    onSuccess() {
      refetch();
      onClose();
      onUpdate();
    }
  });

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: DEFAULT_TRACKER_INFO,
  });

  useEffect(() => {
    if (tracker) {
      form.setValue('enabled', tracker.enabled);
      form.setValue('name', tracker.name);
      form.setValue('url', tracker.url);
      form.setValue('delay', tracker.delay);
      form.setValue('waitForSelector', tracker.waitForSelector);
      form.setValue('limitToSelector', tracker.limitToSelector);
      form.setValue('scrollToBottom', tracker.scrollToBottom);
    }
  }, [form, tracker]);

  if (!tracker) {
    return null;
  }

  const handleSubmit = () => {
    if (!mutation.isLoading) {
      mutation.mutate({
        id: tracker.id,
        ...form.getValues()
      });
    }
  };

  return (
    <Modal open onClose={onClose} title="Edit Tracker">
      <TrackerForm form={form} onSubmit={handleSubmit} loading={mutation.isLoading} />
    </Modal>
  );
}
