"use client"

import { createPortal } from 'react-dom';
import { useEffect } from 'react';

export interface IModalProps {
  open?: boolean;
  onClose?(): void;
  title: string;
  children: any;
}

export function Modal(props: IModalProps) {
  const { open, onClose, title, children } =
    props;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [onClose]);

  if (!open) {
    return null;
  }

  return createPortal(
    <div
      className="fixed top-0 right-0 bottom-0 left-0 grid items-center justify-center cursor-default overflow-auto py-20 bg-[rgba(0,0,0,0.5)]"
      onMouseDown={onClose}
    >
      <div
        onMouseDown={e => e.stopPropagation()}
        className="width-[80%] min-w-[320px] max-w-[800px] border-box"
      >
        <div className="bg-white box-shadow-[0px_1px_8px_black] rounded-md">
          <div className="flex p-6 font-bold text-lg border-b center-items">
            <div className="flex-1 text-uppercase">
              {title || ''}
            </div>
            {onClose && (
              <div className="cursor-pointer" onClick={onClose}>X</div>
            )}
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>,
    document.body
  );
}
