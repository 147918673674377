"use client"

import {
  CSSProperties,
  FormEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  forwardRef,
} from 'react';

export interface IInputProps {
  id?: string;
  required?: boolean;
  autoFocus?: boolean;
  name?: string;
  type: HTMLInputTypeAttribute;
  value?: string | number | boolean;
  onChange(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onKeyDown?(e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  placeholder?: string;
  flex?: boolean;
  pad?: number;
  background?: string;
  height?: string | number;
  width?: string | number;
  style?: CSSProperties;
}

export const Input = forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const { pad = 10, style: overrideStyle } = props;

  const width =
    typeof props.width === 'string'
      ? props.width
      : props.width
      ? `${props.width}px`
      : props.width;

  const style: CSSProperties = {
    flex: props.flex ? '1 1 100%' : 'none',
    backgroundColor: props.background,
    padding: pad && `${pad}px`,
    height: '25px',
    width: width,
    border: '3px solid',
    fontSize: '20px',
    outline: 'none',
    margin: 0,
    borderRadius: 0,
    ...overrideStyle,
  };

  const value =
    typeof props.value === 'boolean'
      ? props.value
        ? 'checked'
        : ''
      : props.value;

  if (props.type === 'textarea') {
    return (
      <textarea
        id={props.id}
        rows={3}
        autoFocus={props.autoFocus}
        name={props.name}
        required={props.required}
        className="Input"
        style={{
          ...style,
          height: 'auto',
        }}
        value={value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        onKeyDown={props.onKeyDown}
      />
    );
  }

  return (
    <input
      ref={ref}
      id={props.id}
      autoFocus={props.autoFocus}
      name={props.name}
      type={props.type}
      required={props.required}
      className="Input"
      style={style}
      value={value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      onKeyDown={props.onKeyDown}
      checked={!!props.value}
    />
  );
});

Input.displayName = 'Input';
